<template>
  <div>
    <loading :enable="load" />
    <div v-if="!load">
      <b-row class="card mt-5">
        <div class="w-100">
          <h5 class="title-card">
            <i class="fas fa-user"></i> Dados Pessoais
          </h5>
        </div>
        <div class="w-100 p-3">
          <b-form>
            <b-row>
              <b-col lg="5">
                <b-form-group label="Nome">
                  <b-form-input
                  :class="{ 'form-group--error': $v.payload.name.$error }"
                  v-model="$v.payload.name.$model"
                  :state="$v.payload.name.$dirty ?
                  !$v.payload.name.$error : null"
                  />
                  <b-form-invalid-feedback>
                    Preenchimento obrigatório
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col lg="7">
                <b-form-group label="Email">
                  <b-form-input
                  :class="{ 'form-group--error': $v.payload.email.$error }"
                  v-model="$v.payload.email.$model"
                  :state="$v.payload.email.$dirty ?
                  !$v.payload.email.$error : null"
                  />
                  <b-form-invalid-feedback>
                    Preenchimento obrigatório
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col lg="4">
                <b-form-group label="Documento (CPF ou CNPJ)">
                  <b-form-input
                  v-mask="['###.###.###-##', '##.###.###/####-##']"
                  :class="{ 'form-group--error': $v.payload.cpf.$error }"
                  v-model="$v.payload.cpf.$model"
                  :state="$v.payload.cpf.$dirty ?
                  !$v.payload.cpf.$error : null"
                  />
                  <b-form-invalid-feedback>
                    Preenchimento obrigatório
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col lg="4">
                <b-form-group label="Celular">
                  <b-form-input
                  v-mask="'(##) # ####-####'"
                  :class="{ 'form-group--error': $v.payload.phone_mobile.$error }"
                  v-model="$v.payload.phone_mobile.$model"
                  :state="$v.payload.phone_mobile.$dirty ?
                  !$v.payload.phone_mobile.$error : null"
                  />
                  <b-form-invalid-feedback>
                    Preenchimento obrigatório
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col lg="4">
                <b-form-group label="Fone Fixo">
                  <b-form-input
                  v-mask="'(##)####-####'"
                  />
                </b-form-group>
            </b-col>
              <!-- <b-col lg="6">
                <b-form-group label="Arquivo (CPF ou RG)">
                  <b-form-file
                  @change="setArchive"
                  browse-text="Procurar"
                  placeholder="Selecione o arquivo ou arraste e solte aqui"
                  drop-placeholder="Solte o arquivo aqui"
                  />
                </b-form-group>
            </b-col> -->
            <b-col lg="3" class="d-flex align-items-center" v-if="file">
              <a :href="`${api}files/${file.path}/${file.filename}`" class="text-dark text-center d-inline-block">
                <i class="fas fa-file-download"></i> <br>
                {{  file.filename }}
              </a>
            </b-col>
            </b-row>
          </b-form>
        </div>
      </b-row>

      <b-row class="card mt-5">
        <div class="w-100">
          <h5 class="title-card">
            <i class="fas fa-home"></i> Endereço
          </h5>
        </div>
        <div class="w-100 p-3">
          <b-row>
              <b-col lg="3">
                <b-form-group label="CEP">
                <searchCep @data="setAddress" :zip_code="payload.address.zip_code" />
                </b-form-group>
              </b-col>

              <b-col lg="7">
                <b-form-group label="Rua">
                  <b-form-input
                  :class="{ 'form-group--error': $v.payload.address.street.$error }"
                  v-model="$v.payload.address.street.$model"
                  :state="$v.payload.address.street.$dirty ?
                  !$v.payload.address.street.$error : null"
                  />
                  <b-form-invalid-feedback>
                    Preenchimento obrigatório
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col lg="2">
                <b-form-group label="Número">
                  <b-form-input
                  v-model="payload.address.number"/>
                </b-form-group>
              </b-col>

              <b-col lg="5">
                <b-form-group label="Bairro">
                  <b-form-input
                  :class="{ 'form-group--error': $v.payload.address.neighborhood.$error }"
                  v-model="$v.payload.address.neighborhood.$model"
                  :state="$v.payload.address.neighborhood.$dirty ?
                  !$v.payload.address.neighborhood.$error : null"
                  />
                  <b-form-invalid-feedback>
                    Preenchimento obrigatório
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col lg="">
                <b-form-group label="Estado">
                  <b-form-select
                  :class="{ 'form-group--error': $v.payload.address.province.$error }"
                  v-model="$v.payload.address.province.$model"
                  :state="$v.payload.address.province.$dirty ?
                  !$v.payload.address.province.$error : null"
                  @change="getCities"
                  :options="provinces"/>
                  <b-form-invalid-feedback>
                    Preenchimento obrigatório
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col lg="">
                <b-form-group label="Cidade">
                <b-form-select
                  :class="{ 'form-group--error': $v.payload.address.city_id.$error }"
                  v-model="$v.payload.address.city_id.$model"
                  :state="$v.payload.address.city_id.$dirty ?
                  !$v.payload.address.city_id.$error : null"
                  :options="cities"/>
                  <b-form-invalid-feedback>
                    Preenchimento obrigatório
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
          </b-row>
        </div>
      </b-row>

      <b-row class="card mt-5">
        <div class="w-100">
          <h5 class="title-card">
            <i class="fas fa-money-check-alt"></i> Dados bancários
          </h5>
        </div>

        <div class="w-100 p-3">
          <b-row>
            <b-col lg="4">
              <b-form-group label="Tipo de conta">
                <b-form-select
                :class="{ 'form-group--error': $v.payload.bank_account.person_type.$error }"
                v-model="$v.payload.bank_account.person_type.$model"
                :state="$v.payload.bank_account.person_type.$dirty ?
                !$v.payload.bank_account.person_type.$error : null"
                :options="person_type"></b-form-select>
                <b-form-invalid-feedback>
                  Preenchimento obrigatório
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="5">
              <b-form-group label="Banco">
                <Autocomplete
                ref="autocomplete"
                :source="optionsBanks"
                :results-display="formattedDisplayListBanks"
                input-class="form-control"
                :initialDisplay="payload.bank_account.bank_name"
                :initialValue="payload.bank_account.bank_code"
                resultsValue="code"
                @selected="setBank"
                placeholder="Pesquisar banco"
                />
              </b-form-group>
            </b-col>
            <b-col lg="3">
              <b-form-group label="Agência">
                <b-form-input
                :class="{ 'form-group--error': $v.payload.bank_account.agency.$error }"
                v-model="$v.payload.bank_account.agency.$model"
                :state="$v.payload.bank_account.agency.$dirty ?
                !$v.payload.bank_account.agency.$error : null"
                />
                <b-form-invalid-feedback>
                  Preenchimento obrigatório
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col lg="4">
              <b-form-group label="Conta">
                <b-form-input
                :class="{ 'form-group--error': $v.payload.bank_account.account.$error }"
                v-model="$v.payload.bank_account.account.$model"
                :state="$v.payload.bank_account.account.$dirty ?
                !$v.payload.bank_account.account.$error : null"
                />
                <b-form-invalid-feedback>
                  Preenchimento obrigatório
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <div class="w-100 text-right mt-5">
            <b-button variant="success" @click="save(false)">
            <i class="fas fa-save"></i> Salvar
            </b-button>
          </div>
        </div>
      </b-row>
     </div>
  </div>
</template>
<script>
import Autocomplete from 'vuejs-auto-complete';
import HelperService from '@/Services/HelperService';
import { required, email, numeric } from 'vuelidate/lib/validators';
import { banks } from '@/assets/bancos.json';
import MessagesMixin from '@/mixins/messages-mixin';
import QuestionMixin from '@/mixins/question-mixin';
import ErrorMixin from '@/mixins/error-mixin';
import AgentService from '@/Services/AgentService';
import UploadService from '@/Services/UploadService';

export default {
  components: {
    Autocomplete,
  },
  props: ['id'],
  mixins: [MessagesMixin, QuestionMixin, ErrorMixin],
  data() {
    return {
      api: process.env.VUE_APP_API,
      load: false,
      provinces: [
        {
          text: 'Selecione',
          value: '',
        },
      ],
      cities: [
        {
          text: 'Selecione',
          value: '',
        },
      ],
      person_type: [
        {
          text: 'Selecione',
          value: '',
        },
        {
          value: 'pessoa_fisica',
          text: 'Pessoa Física',
        },
        {
          value: 'pessoa_juridica',
          text: 'Pessoa Jurídica',
        },
      ],
      optionsBanks: banks,
      file: '',
      payload: {
        name: '',
        email: '',
        id: '',
        cpf: '',
        phone_mobile: '',
        file_entry_id: '',
        phone: '',
        address: {
          zip_code: '',
          street: '',
          number: '',
          neighborhood: '',
          province: '',
          city_id: '',
          address_type: 'address',
        },
        bank_account: {
          bank_name: '',
          bank_code: '',
          person_type: '',
          agency: '',
          account: '',
        },
      },
    };
  },

  validations: {
    payload: {
      name: { required },
      email: { required, email },
      cpf: { required },
      phone_mobile: { required },
      address: {
        street: { required },
        neighborhood: { required },
        province: { required },
        city_id: { required },
      },
      bank_account: {
        person_type: { required },
        agency: { required, numeric },
        account: { required, numeric },
      },
    },
  },

  methods: {
    async getProvinces() {
      const { data } = await HelperService.getProvinces();
      data.forEach((item) => {
        this.provinces.push({
          text: item.name,
          value: item.id,
        });
      });
    },

    async getAgent() {
      this.load = true;
      const { data } = await AgentService.get(this.id);

      this.payload.id = data.id;
      this.payload.name = data.name;
      this.payload.email = data.email;
      this.payload.cpf = data.cpf;
      this.payload.phone_mobile = data.phone_mobile;
      this.payload.phone = data.phone;

      this.payload.address.province = data.address.city.province_id;
      this.payload.address.city_id = data.address.city.id;
      this.getCities();
      this.payload.address.city_id = data.address.city.id;
      this.payload.address.id = data.address.id;
      this.payload.address.zip_code = data.address.zip_code;
      this.payload.address.street = data.address.street;
      this.payload.address.number = data.address.number;
      this.payload.address.neighborhood = data.address.neighborhood;

      this.payload.bank_account.id = data.bank_account.id;
      this.payload.bank_account.bank_code = data.bank_account.bank_code;
      this.payload.bank_account.bank_name = data.bank_account.bank_name;
      this.payload.bank_account.person_type = data.bank_account.person_type;
      this.payload.bank_account.agency = data.bank_account.agency;
      this.payload.bank_account.account = data.bank_account.account;

      this.file = data.file_entry;

      this.load = false;
    },

    formattedDisplayListBanks(result) {
      return `${result.code} - ${result.name}`;
    },

    setBank(bank) {
      this.payload.bank_account.bank_code = bank.selectedObject.code;
      this.payload.bank_account.bank_name = bank.selectedObject.name;
    },

    async getCities() {
      const params = {
        province_id: this.payload.address.province,
      };

      const { data } = await HelperService.getCities(params);
      data.forEach((item) => {
        this.cities.push({
          text: item.name,
          value: item.id,
        });
      });
    },

    setAddress(data) {
      this.payload.address.zip_code = data.cep;
      this.payload.address.street = data.logradouro;
      this.payload.address.province = data.id_estado;
      this.payload.address.neighborhood = data.bairro;

      this.getCities();
      setTimeout(() => {
        // eslint-disable-next-line max-len
        this.payload.address.city_id = this.cities.find((item) => HelperService.removeAcentos(item.text) === HelperService.removeAcentos(data.localidade)).value;
      }, 1000);
    },

    async setArchive({ target }) {
      const formData = new FormData();
      formData.append('file', target.files[0]);

      const { data } = await UploadService.send(formData);
      this.payload.file_entry_id = data.id;
    },

    async save() {
      this.$v.payload.$touch();
      if (!this.$v.payload.$invalid) {
        try {
          const { data } = !this.id ? await AgentService.save(this.payload) : await AgentService.edit(this.payload);
          this.messageSuccess(`O agente ${data.name} foi cadastrado com sucesso`);
          this.$router.push({ name: 'agents' });
        } catch (error) {
          console.log(error);
          this.messageError('Nome ou email já cadastrado');
        }
      }
    },
  },
  created() {
    this.getProvinces();
    if (this.id) this.getAgent();
  },
};
</script>
